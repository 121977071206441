<template>
    <div class="product">
      <ProductData :product="product" />
      <ProductGalleryWrapper :images="activeVariant?.images" />
      <ProductActions :product="product" @variantSelected="updateActiveVariant" 
      @OpenWishList="openWish"
      @OpenCartList="openCart"/>
      
    </div>
    <ProductInfo :product="product" />
    <ProductReviews v-if="product && product?.id" :productId="product?.id" />
  </template>
  
  <script>
  import ProductData from './ProductComponents/ProductData.vue';
  import ProductGalleryWrapper from './ProductComponents/ProductGalleryWrapper.vue';
  import ProductActions from './ProductComponents/ProductActions.vue';
  import ProductInfo from './ProductComponents/ProductInfo.vue';
  import ProductReviews from './ProductComponents/ProductReviews.vue';

export default {
    name: 'ProductComponent',
    components: {
      ProductData,
      ProductGalleryWrapper,
      ProductActions,
      ProductInfo,
      ProductReviews,
    },
    props: {
      product: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        activeVariant: this.product?.variant?.[0] || null,
      };
    },
    methods: {
      updateActiveVariant(variant) {
        console.log('Received variantSelected event with variant:', variant);
        this.activeVariant = variant;
      },
      openWish(){
        this.$emit('OpenWishList');
      },
      openCart(){
        this.$emit('openCartList');
      },
    },
  };
  </script>
  
  
  <style scoped>
  .product {
    display: flex;
    gap: 24px;
  }
  @media (max-width:960px) {
    .product {
      flex-direction: column;
      align-items: center;
    }
  }
  </style>
  