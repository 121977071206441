<template>
  <li class="catalogue__product__wrapper">
    <div class="catalogue__product" :id="`product-${product.id}`">
      <!-- Зображення продукту -->
      <a class="image" :href="product.url">
        <img
          rel="preload"
          loading="lazy"
          class="lazy"
          :src="selectedVariant?.images?.[0]?.s || product.image"
          :alt="product.name"
          as="image"
        />
      </a>
      <!-- Інформація про продукт -->
      <div class="info">
        <a class="name" :href="product.url">
          <span>{{ product.name }}</span>
        </a>

        <span class="human" v-if="product.human">
          <!-- Обрізка опису до 70 символів -->
          <span>{{ truncatedHumanText }}</span>
        </span>
        
        <span class="brand">{{ product.brand }}</span>

          <div
            class="variants-selector"
            v-if="Array.isArray(product.variants) && product.variants.length > 1"
          >
            <select
              v-model="selectedVariant"
              @change="handleVariantChange"
            >
              <option
                v-for="variant in product.variants"
                :key="variant.id"
                :value="variant"
              >
                {{ variant.value }} {{ product.unit }}
              </option>
            </select>
          </div>

    <!-- Відображення одного варіанту -->
    <div
      class="single-variant-display"
      v-else-if="Array.isArray(product.variants) && product.variants.length === 1"
    >
      {{ product.variants[0].value }} {{ product.unit }}
    </div>
        <!-- Ціна продукту -->
        <div class="price-wrapper">
          <span
            class="price"
            :class="{ discount: hasDiscountPrice }"
          >{{ displayPrice }} {{ $t('currency') }}</span>
          <span
            class="discount-price"
            v-if="hasDiscountPrice"
          >{{ displayDiscountPrice }} {{ $t('currency') }}</span>
        </div>
      </div>

      <!-- Кнопки дій -->
      <div :class="isAvailable ? 'buttons' : 'preorder_button'">
        <button
          class="add_to_cart"
          :data-id="selectedVariant?.id || product.id"
          :data-name="product.name"
          data-quantity="1"
          :data-brand="product.brand"
          :data-price="displayPrice"
          @click="addProductToCart"
        >
          {{ isAvailable ? $t('product.add_to_cart') : $t('product.preorder') }}
        </button>

        <!-- Додавання до вішліста -->
        <span :data-id="product.id" @click="handleWishlistClick">
          <img src="@/assets/img/icons/svg/Heart.svg" alt="wishlist" class="ico" />
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ProductCard',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showFullDescription: false,
      selectedVariant: Array.isArray(this.product.variants) && this.product.variants.length ? this.product.variants[0] : null
    };
  },
  computed: {
    ...mapGetters('wishlist', ['wishlistItems', 'isProductInWishlist']),
    ...mapGetters('auth', ['isWhoosaler']),
    
    displayPrice() {
      if (this.isWhoosaler) {
        return this.selectedVariant?.whoosale_price || this.product.whoosale_price;
      }
      return this.selectedVariant?.price || this.product.price;
    },
    displayDiscountPrice() {
      if (this.isWhoosaler) {
        return this.selectedVariant?.discount_whoosale_price || this.product.discount_whoosale_price;
      }
      return this.selectedVariant?.discount_price || this.product.discount_price;
    },
    hasDiscountPrice() {
      return this.displayDiscountPrice > 0;
    },
    isAvailable() {
    if (this.isWhoosaler) {
      return (this.selectedVariant?.stock_opt || 0) > 0;
    } else {
      // Якщо користувач не оптовий, перевіряємо stock + stock_opt
      return (this.selectedVariant?.stock || 0) + (this.selectedVariant?.stock_opt || 0) > 0;
    }
  },

    isInWishlist() {
      return this.isProductInWishlist(this.selectedVariant?.id || this.product.id);
    },
    descriptionIsLong() {
    return this.product.human && this.product.human.length > 70; // Перевірка довжини тексту
  },
  truncatedHumanText() {
    if (!this.descriptionIsLong) {
      return this.product.human;  // Якщо текст не довший за 70 символів, повертаємо його без змін
    }
    // Якщо текст довший за 70 символів, обрізаємо і додаємо "..."
    return this.product.human.slice(0, 70) + '...';
  },
  },
  methods: {
    ...mapActions('wishlist', ['addToWishlist']),
    ...mapActions('cart', ['addToCart']), // Додаємо метод addToCart з Vuex
    handleVariantChange(event) {
      this.$emit("variant-selected", event.target.value);
    },
    mounted() {
    // Якщо є лише один варіант, встановлюємо його автоматично
    if (this.product.variants.length === 1) {
      this.$emit("variant-selected", this.product.variants[0]);
    }
  },
    addProductToCart() {
      const productData = {
        id: this.selectedVariant?.id || this.product.id,
        image: this.selectedVariant?.images?.[0]?.s || this.product.image,
        name: this.product.name,
        brand: this.product.brand,
        code: this.selectedVariant?.code,
        price: this.displayDiscountPrice || this.displayPrice,
        quantity: 1, 
      };
      this.addToCart(productData);
      this.$emit('OpenCartList');
    },
    handleWishlistClick() {
      const productData = {
        id: this.selectedVariant?.id || this.product.id,
        image: this.selectedVariant?.images?.[0]?.xs || this.product.image,
        name: this.product.name,
        code: this.selectedVariant?.code || this.product.code,
        price: this.displayDiscountPrice || this.displayPrice,
      };
      this.addToWishlist(productData);
      this.$emit('OpenWishList');
    },
    selectVariant(variant) {
      this.selectedVariant = variant;
    }
  }
};
</script>


  
<style scoped>
 .catalogue__product__wrapper {
    position: relative;
    display: block;
    max-width: 540px;
}


.catalogue__product {
    position: relative;
    display: block;
}
.catalogue__product .image {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding-top: 100%;
    overflow: hidden;
}
.catalogue__product .image img {
    position: absolute;
    display: block;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: blur(0px);
    transition: filter 0.3s ease-in-out;
}
.catalogue__product .image.blank img {
    filter: unset;
}


.catalogue__product .image img.loaded {
    filter: blur(0px);
}
.catalogue__product .info {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;
}
.catalogue__product .info > * {
    margin-top: 12px;
}
.catalogue__product .info ul.variants {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    visibility: visible;
}


.catalogue__product .info ul.variants li {
    position: relative;
    display: block;
    padding: 8px 16px;
    border: 2px solid whitesmoke;
    margin: 8px 4px 0px 4px;
}
.catalogue__product .info .brand {
    font-size: 14px;
    color: var(--clr-2);
}
.catalogue__product .info .name {
    font-weight: 500;
    height: 70px;
    text-align: center;
    margin-right: 0;
    max-width: calc(100% - 8px);
    overflow: hidden;
    font-size: var(--h5);
}
.catalogue__product .info .human {
    font-size: 14px;
    text-align: center;
    color: grey;
    height: 40px;
    overflow: hidden;
}
.catalogue__product .info .price {
    font-weight: 500;

}

.preorder_button button {
  color: #000;
}
.catalogue__product .buttons,
.catalogue__product .preorder_button{
    position: relative;
    flex-grow: 1;
    margin-top: 16px;
    display: grid;
    width: 95%;
    grid-template-columns: 1fr 48px;
    grid-gap: 8px;
    height: 48px;
    visibility: visible;
    opacity: 1;
}


.catalogue__product .buttons .button {
    display: inline-block;
    padding: 2px 6px;
    border: 2px solid var(--clr-1);
    background-color: transparent;
    color: black;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
}



.catalogue__product .buttons button {
    border: none;
    border-radius: 4px;
    background-color: var(--clr-2);
    color: white;
    cursor: pointer;
}
.catalogue__product .buttons button:hover {
    background-color: var(--clr-3);
}

.catalogue__product .buttons span,
.catalogue__product .preorder_button span{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;
    border-radius: 4px;
}
.catalogue__product .buttons span img,
.catalogue__product .preorder_button span img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: width 0.3s ease, height 0.3s ease;
}
.catalogue__product .buttons span img:hover,
.catalogue__product .preorder_button span img:hover {
    width: 30px;
    height: 30px;
    transition: width 0.3s ease, height 0.3s ease;
}

.discount_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.discount_wrapper span:first-child {
    margin-right: 8px;
}
.discount_price {
    color: var(--clr-2);
    text-decoration: line-through;
    font-weight: 500;
}
@media only screen and (max-width: 420px) {

    .preorder_button button{
      font-size: 10px;
    }
}
.preorder_button button{
    display: inline-block;
    border: 2px solid var(--clr-1);
    background-color: transparent;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
}
.preorder_button button:hover {
    color: white;
    background: var(--clr-1);
}
.price-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.price {
  font-weight: 500;
}

.price.discount {
  color: grey;
  text-decoration: line-through;
}

.discount-price {
  color: var(--clr-2); /* Червоний колір */
  font-weight: 500;
}



</style>
  