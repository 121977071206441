import { VueReCaptcha } from 'vue-recaptcha-v3';

export default {
  install(app) {
    app.use(VueReCaptcha, {
      siteKey: '6LdL_KIqAAAAAIb3YwyYX5sc56ndq0jIPh2FaPA_',
      loaderOptions: {
        autoHideBadge: false,
        explicitRenderParameters: {
          badge: 'bottomleft', 
        },
      },
    });
  },
};


