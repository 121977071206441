import apiClient  from '@/api/axios';

// Vuex store для cart.js
const CART_KEY = 'cart';

// Ініціалізація кошика з локального сховища
const getCartFromStorage = () => JSON.parse(localStorage.getItem(CART_KEY)) || [];

const state = {
    items: getCartFromStorage()
};

const mutations = {
    APPLY_PROMO: async function (state, { discount, brand }) {
        console.log("Applying promo:", { discount, brand });
    
        // Отримуємо статус isWholesale з локального сховища або встановлюємо false за замовчуванням
        const isWholesale = localStorage.getItem("isWhoosaler") === "true";
    
        for (const item of state.items) {
            // Перевірка, чи товар належить до бренду з промокоду
            if (item.brand === brand) {
                try {
                    // Отримуємо дані з бекенду на основі `code`
                    const response = await apiClient.get(`/shop/variants/${item.code}/`);
        
                    // Залежно від статусу користувача використовуємо правильну ціну
                    const basePrice = isWholesale
                        ? response.data.whoosale_price
                        : response.data.price;
        
                    const discountedPrice =
                        typeof discount === "number"
                            ? basePrice * (1 - discount / 100) // Знижка у відсотках
                            : basePrice - discount; // Фіксована сума знижки
        
                    // Розраховуємо знижку
                    const promoDiscount = discount;
        
                    // Оновлюємо ціну тільки якщо зі знижкою вона менша за поточну
                    if (discountedPrice < item.price) {
                        item.price = discountedPrice;
                        item.promo_discount = promoDiscount; // Додаємо значення знижки
                    } else {
                        item.promo_discount = 0; // Якщо знижки немає, додаємо 0
                    }
                } catch (error) {
                    console.error(`Помилка отримання даних для товару ${item.code}:`, error);
                    item.promo_discount = 0; // У разі помилки додаємо 0
                }
            } else {
                // Якщо бренд не збігається, поле promo_discount встановлюємо в 0
                item.promo_discount = 0;
            }
        }
        
    
        // Оновлюємо локальне сховище
        localStorage.setItem(CART_KEY, JSON.stringify(state.items));
    
        console.log("Cart after promo application:", state.items);
    },
    
    
    SET_CART(state, cart) {
        state.items = cart;
        localStorage.setItem(CART_KEY, JSON.stringify(cart));
    },
    ADD_TO_CART(state, product) {
        const itemIndex = state.items.findIndex(item => item.id === product.id);
        if (itemIndex > -1) {
            state.items[itemIndex].quantity += product.quantity;
        } else {
            state.items.push(product);
        }
        console.log(product)
        localStorage.setItem(CART_KEY, JSON.stringify(state.items));
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'addToCart',
                ecommerce: {
                    currencyCode: 'UAH',
                    add: {
                        product: [{
                            id: product.id,
                            code: product.code,
                            product_name: product.name,
                            price: product.price,
                            discount_price: product.discount_price || product.price,
                            quantity: product.quantity
                        }]
                    }
                }
            });
        }
    },
    REMOVE_FROM_CART(state, productId) {
        state.items = state.items.filter(item => item.id !== productId);
        localStorage.setItem(CART_KEY, JSON.stringify(state.items));
    },
    UPDATE_QUANTITY(state, { productId, quantity, productData }) {
        const item = state.items.find(item => item.id === productId);
        if (item) {

            item.quantity = quantity;
        } else {

            state.items.push({
                id: productData.id,
                code: productData.code,
                image: productData.image,
                name: productData.name,
                price: productData.price,
                quantity: quantity,
            });
        }
        localStorage.setItem(CART_KEY, JSON.stringify(state.items));
    },
    
    CLEAR_CART(state) {
        state.items = [];
        localStorage.removeItem(CART_KEY);
    }
};

const actions = {
    async applyPromo({ commit }, { discount, brand}) {
        commit('APPLY_PROMO', { discount, brand });
    },
    addToCart({ commit }, product) {
        console.log(product)
        commit('ADD_TO_CART', product);
    },
    removeFromCart({ commit }, productId) {
        commit('REMOVE_FROM_CART', productId);
    },
    updateQuantity({ commit }, { productId, quantity, productData }) {
        commit('UPDATE_QUANTITY', { productId, quantity, productData });
    },
    clearCart({ commit }) {
        commit('CLEAR_CART');
    },
    fetchCart({ commit }) {
        const cart = getCartFromStorage();
        commit('SET_CART', cart);
    }
};

const getters = {
    cartItems: state => state.items,
    cartTotalPrice: state => {
        return state.items.reduce((total, item) => {
            return total + item.price * item.quantity;
        }, 0);
    },
    
    cartItemCount: state => state.items.reduce((total, item) => total + item.quantity, 0),
    getProductQuantity: state => productId => {
        const item = state.items.find(item => item.id === productId);
        return item ? item.quantity : 0;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
