<template>
  <div class="content">
    <div class="user_registration pb64 registration__partner">
      <div v-if="isLoading" class="overlay">
        <div class="spinner"></div>
      </div>
      <p class="h1 center">{{ $t('login.partner_register') }}</p>
      <form @submit.prevent="handleRegister" class="form validate" novalidate>
        <!-- Особиста інформація -->
        <div class="fields personal">
          <div>
            <input
              v-model="email"
              type="email"
              placeholder="Email *"
              required
              :class="{ 'input-error': fieldErrors.email }"
              @blur="validateField('email')"
            />
            <p class="msg" v-if="fieldErrors.email">{{ fieldErrors.email }}</p>
          </div>
          <div>
            <input
              v-model="phone"
              type="text"
              :placeholder="$t('login.phone')"
              required
              :class="{ 'input-error': fieldErrors.phone }"
              @blur="validateField('phone')"
            />
            <p class="msg" v-if="fieldErrors.phone">{{ fieldErrors.phone }}</p>
          </div>
          <div class="password-field">
            <input
              :type="showPassword ? 'text' : 'password'"
              v-model="password1"
              :placeholder="$t('login.password_placeholder')"
              required
              :class="{ 'input-error': fieldErrors.password1 }"
              @blur="validateField('password1')"
            />
            <span @click="togglePasswordVisibility" class="toggle-password">
              {{ showPassword ? '🔓' : '🔒' }}
            </span>
            <p class="msg" v-if="fieldErrors.password1">{{ fieldErrors.password1 }}</p>
          </div>
          <div class="password-field">
            <input
              :type="showPassword ? 'text' : 'password'"
              v-model="password2"
              :placeholder="$t('login.password_placeholder')"
              required
              :class="{ 'input-error': fieldErrors.password2 }"
              @blur="validateField('password2')"
            />
            <span @click="togglePasswordVisibility" class="toggle-password">
              {{ showPassword ? '🔓' : '🔒' }}
            </span>
            <p class="msg" v-if="fieldErrors.password2">{{ fieldErrors.password2 }}</p>
          </div>
          <div>
            <input
              v-model="firstName"
              type="text"
              :placeholder="$t('contactForm.firstNamePlaceholder')"
              required
              :class="{ 'input-error': fieldErrors.firstName }"
              @blur="validateField('firstName')"
            />
            <p class="msg" v-if="fieldErrors.firstName">{{ fieldErrors.firstName }}</p>
          </div>
          <div>
            <input
              v-model="lastName"
              type="text"
              :placeholder="$t('order.last_name')"
              required
              :class="{ 'input-error': fieldErrors.lastName }"
              @blur="validateField('lastName')"
            />
            <p class="msg" v-if="fieldErrors.lastName">{{ fieldErrors.lastName }}</p>
          </div>
          <p><input v-model="fatherName" type="text" :placeholder="$t('order.father_name')" /></p>
          <p><input v-model="city" type="text" :placeholder="$t('order.city')" /></p>
        </div>

        <!-- Інформація про компанію -->
        <p class="title">{{ $t('login.company_info') }}</p>
        <div class="fields business__formset">
          <div>
            <input
              v-model="companyName"
              type="text"
              :placeholder="$t('contactForm.company')"
              required
              :class="{ 'input-error': fieldErrors.companyName }"
              @blur="validateField('companyName')"
            />
            <p class="msg" v-if="fieldErrors.companyName">{{ fieldErrors.companyName }}</p>
          </div>
          <div>
            <input
              v-model="position"
              type="text"
              :placeholder="$t('contactForm.position')"
              required
              :class="{ 'input-error': fieldErrors.position }"
              @blur="validateField('position')"
            />
            <p class="msg" v-if="fieldErrors.position">{{ fieldErrors.position }}</p>
          </div>
        </div>

        <button type="submit">{{ $t('login.to_register') }}</button>
        <p class="msg error" v-if="fieldErrors.general">{{ fieldErrors.general }}</p>
      </form>
    </div>
  </div>
</template>

<script>
import apiClient from "@/api/axios";

export default {
  data() {
    return {
      email: "",
      phone: "",
      password1: "",
      password2: "",
      firstName: "",
      lastName: "",
      fatherName: "",
      city: "",
      companyName: "",
      position: "",
      fieldErrors: {},
      isSubmitted: false,
      isLoading: false,
      showPassword: false,
    };
  },
  methods: {
    validateField(field) {
      const value = this[field];
      switch (field) {
        case "email":
          this.fieldErrors.email = !this.validateEmail(value)
            ? this.$t("registration.errors.email")
            : null;
          break;
        case "phone":
          this.fieldErrors.phone = !this.validatePhone(value)
            ? this.$t("registration.errors.phone")
            : null;
          break;
        case "password1":
          this.fieldErrors.password1 = !this.validatePassword(value)
            ? this.$t("registration.errors.password1")
            : null;
          break;
        case "password2":
          this.fieldErrors.password2 =
            this.password1 !== this.password2
              ? this.$t("registration.errors.password_mismatch")
              : null;
          break;
        case "firstName":
        case "lastName":
        case "companyName":
        case "position":
          this.fieldErrors[field] = !this.validateText(value)
            ? this.$t(`registration.errors.${field}`)
            : null;
          break;
        default:
          break;
      }
    },
    validateForm() {
      const fields = [
        "email",
        "phone",
        "password1",
        "password2",
        "firstName",
        "lastName",
        "companyName",
        "position",
      ];
      fields.forEach((field) => this.validateField(field));
      return !Object.values(this.fieldErrors).some((error) => error !== null);
    },
    validateEmail(value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    },
    validatePhone(value) {
      const phoneRegex = /^[+]?[0-9\s()-]{10,15}$/;
      return phoneRegex.test(value);
    },
    validatePassword(value) {
      return value && value.length >= 8;
    },
    validateText(value) {
      return /^[a-zA-Zа-яА-ЯіІїЇєЄ\s'-]+$/u.test(value);
    },
    async handleRegister() {
      this.fieldErrors = {};
      if (!this.validateForm()) {
        return;
      }

      this.isLoading = true;
      const userData = {
        email: this.email,
        phone: this.phone,
        password: this.password1,
        first_name: this.firstName,
        last_name: this.lastName,
        father_name: this.fatherName,
        city: this.city,
        company_name: this.companyName,
        position: this.position,
      };

      try {
        // eslint-disable-next-line 
        const response = await apiClient.post("/auth/register_partner/", userData);
        this.$router.push({ name: "RegistrationConfirmation" });
      } catch (error) {
        if (error.response?.data) {
          this.fieldErrors = error.response.data;
        } else {
          this.fieldErrors.general = this.$t("registrations.error");
        }
      } finally {
        this.isLoading = false;
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>



<style scoped>
.input-error {
  border: 1px solid red;
}
.fieldset input {
    width: 100% !important;
  }
.msg {
  color: red;
  font-size: 12px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.password-field {
  position: relative;
}
.toggle-password {
  position: absolute;
  top: 45%;
  right: 45px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: #666;
}

.registration__partner {
    position: relative;
    display: block;
    margin: 0 auto 64px auto;
    max-width: 1200px;
}

.form {
    margin-top: 48px;
}
.title {
    margin: 24px 0;
    font-size: var(--h4);
    font-weight: 500;
}
.fields.business__formset,
.fields.personal {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
}
@media(max-width: 526px) {
  .fields.business__formset,
  .fields.personal {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 14px;
  }
  .fieldset p {
    text-align: center;
  }
}
.fields.business__formset > p:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: -1;
}
.fields > p {
    margin-bottom: 0 !important;
}

.fields.business__formset input[type="checkbox"] {
    float: left;
    width: 24px;
}
.input-error {
  border-color: red;
}
.msg {
  color: red;
  font-size: 12px;
}
.fields li {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
</style>
