<template>
  <div>
    <div class="home-about">
      <div class="content">
        <img :src="logo" alt="Logo" class="logo" width="240" height="44" />

      </div>
    </div>

    <Suspense>
      <template #default>
        <HomeAdvantages />
      </template>
      <template #fallback>
        <HomeAdvantagesSkeleton />
      </template>
    </Suspense>
  </div>
</template>

<script>
import HomeAdvantagesSkeleton from '@/components/HomeAdvantagesSkeleton.vue';
import HomeAdvantages from '@/components/HomeAdvantages.vue';

export default {
  name: 'HomeAbout',
  components: {
    HomeAdvantages,
    HomeAdvantagesSkeleton,
  },
  props: {
    slogan: {
      type: String,
      required: true
    },
    logo: {
      type: String,
      required: true
    },
  }
}
</script>
