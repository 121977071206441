

<template>
  <div class="data profile_data">
    <div class="personal__info forms">
      <ul>
        <!-- Особиста інформація -->
        <li class="block user__personal-info">
          <input class="edit" type="checkbox" v-model="isEditing" />
          <div class="heading">
            <p class="title2">{{ $t('personalInfo.title') }}</p>
            <label @click="toggleEditing">
              <img src="/static/img/icons/edit.png" :alt="$t('personalInfo.editAlt')" />
              <span v-if="!isEditing">{{ $t('personalInfo.edit') }}</span>
              <span v-else>{{ $t('personalInfo.cancelEdit') }}</span>
            </label>
          </div>
          <form @submit.prevent="updatePersonalInfo" class="form">
            <div class="fields">
              <div>
                <label for="first_name">{{ $t('personalInfo.firstName') }}:</label>
                <p>
                  <input
                    type="text"
                    id="first_name"
                    v-model="formData.first_name"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
              <div>
                <label for="last_name">{{ $t('personalInfo.lastName') }}:</label>
                <p>
                  <input
                    type="text"
                    id="last_name"
                    v-model="formData.last_name"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
              <div>
                <label for="father_name">{{ $t('personalInfo.fatherName') }}:</label>
                <p>
                  <input
                    type="text"
                    id="father_name"
                    v-model="formData.father_name"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
              <div>
                <label for="phone">{{ $t('personalInfo.phone') }}:</label>
                <p>
                  <input
                    type="text"
                    id="phone"
                    v-model="formData.phone"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
              <div>
                <label for="country">{{ $t('personalInfo.country') }}:</label>
                <p>
                  <input
                    type="text"
                    id="country"
                    v-model="formData.country"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
              <div>
                <label for="city">{{ $t('personalInfo.city') }}:</label>
                <p>
                  <input
                    type="text"
                    id="city"
                    v-model="formData.city"
                    :disabled="!isEditing"
                  />
                </p>
              </div>
            </div>
            <button type="submit" v-if="isEditing">{{ $t('personalInfo.updateButton') }}</button>
          </form>
        </li>
        <!-- CHANGE PASSWORD -->
        <li class="block user__change-password">
          <input class="edit" type="checkbox" v-model="isChangingPassword" />
          <div class="heading">
            <p class="title2">{{ $t('passwordChange.title') }}</p>
            <label @click="togglePasswordChange">
              <img src="/static/img/icons/edit.png" :alt="$t('passwordChange.editAlt')" />
              <span v-if="!isChangingPassword">{{ $t('passwordChange.edit') }}</span>
              <span v-else>{{ $t('passwordChange.cancelEdit') }}</span>
            </label>
          </div>
          <form @submit.prevent="changePassword" class="form">
            <div class="fields">
           <div>
            <label for="current_password">{{ $t('passwordChange.current') }}:</label>
            <p class="password-field">
              <input
                :type="showCurrentPassword ? 'text' : 'password'"
                id="current_password"
                v-model="passwordData.current_password"
                required
                :disabled="!isChangingPassword"
                :class="{ active: isChangingPassword }"
              />
              <span 
                v-if="isChangingPassword"
                class="toggle-password"
                @click="toggleShowPassword('current')"
              >
                {{ showCurrentPassword ? '🔓' : '🔒' }}
              </span>
            </p>
          </div>
          <div>
            <label for="new_password">{{ $t('passwordChange.new') }}:</label>
            <p class="password-field">
              <input
                :type="showNewPassword ? 'text' : 'password'"
                id="new_password"
                v-model="passwordData.new_password"
                required
                :disabled="!isChangingPassword"
                :class="{ active: isChangingPassword }"
              />
              <span
                v-if="isChangingPassword"
                class="toggle-password"
                @click="toggleShowPassword('new')"
              >
                {{ showNewPassword ? '🔓' : '🔒' }}
            </span>
            </p>
            </div>
            <div>
              <label for="re_new_password">{{ $t('passwordChange.confirm') }}:</label>
              <p class="password-field">
                <input
                  :type="showConfirmPassword ? 'text' : 'password'"
                  id="re_new_password"
                  v-model="passwordData.re_new_password"
                  required
                  :disabled="!isChangingPassword"
                  :class="{ active: isChangingPassword }"
                />
                <span
                  v-if="isChangingPassword"
                  class="toggle-password"
                  @click="toggleShowPassword('confirm')"
                >
                  {{ showConfirmPassword ? '🔓' : '🔒' }}
              </span>
              </p>
            </div>

            </div>

            <button type="submit" v-if="isChangingPassword">{{ $t('passwordChange.updateButton') }}</button>
          </form>
          <p v-if="passwordSuccessInfo" class="success-message">{{ passwordSuccessInfo }}</p>
          <p v-if="passwordError" class="error-message">{{ passwordError }}</p>
        </li>

        <!-- Кур'єрська доставка -->
        <li class="block user__delivery-info">
          <input class="edit" type="checkbox" v-model="isEditingCourier" />
          <div class="heading">
            <p class="title2">{{ $t('deliveryInfo.curier') }}</p>
            <label @click="toggleEditingCourier">
              <img src="/static/img/icons/edit.png" :alt="$t('deliveryInfo.editAlt')" />
              <span v-if="!isEditingCourier">{{ $t('deliveryInfo.edit') }}</span>
              <span v-else>{{ $t('deliveryInfo.cancelEdit') }}</span>
            </label>
          </div>
          <form @submit.prevent="updateCourierAddress" class="form">
            <div class="fields">
              <div v-for="(value, key) in courierAddress" :key="key">
                <label :for="key">{{ $t(`deliveryInfo.${key}`) }}:</label>
                <p>
                  <input
                    type="text"
                    :id="key"
                    v-model="courierAddress[key]"
                    @input="validateCourierField(key)"
                    :disabled="!isEditingCourier"
                  />
                  <span v-if="courierErrors[key]" class="error-message">{{ courierErrors[key] }}</span>
                </p>
              </div>
            </div>
            <button type="submit" v-if="isEditingCourier" :disabled="!isCourierValid">{{ $t('deliveryInfo.updateButton') }}</button>
          </form>
        </li>

        <!-- Нова Пошта -->
        <li class="block user__delivery-info">
          <input class="edit" type="checkbox" v-model="isEditingNewPost" />
          <div class="heading">
            <p class="title2">{{ $t('deliveryInfo.newpost') }}</p>
            <label @click="toggleEditingNewPost">
              <img src="/static/img/icons/edit.png" :alt="$t('deliveryInfo.editAlt')" />
              <span v-if="!isEditingNewPost">{{ $t('deliveryInfo.edit') }}</span>
              <span v-else>{{ $t('deliveryInfo.cancelEdit') }}</span>
            </label>
          </div>
          <form @submit.prevent="updateNewPostAddress" class="form">
            <div class="fields">
              <div v-for="(value, key) in newPostAddress" :key="key">
                <label :for="key">{{ $t(`deliveryInfo.${key}`) }}:</label>
                <p>
                  <input
                    type="text"
                    :id="key"
                    v-model="newPostAddress[key]"
                    @input="validateNewPostField(key)"
                    :disabled="!isEditingNewPost"
                  />
                  <span v-if="newPostErrors[key]" class="error-message">{{ newPostErrors[key] }}</span>
                </p>
              </div>
            </div>
            <button type="submit" v-if="isEditingNewPost" :disabled="!isNewPostValid">{{ $t('deliveryInfo.updateButton') }}</button>
          </form>
        </li>

      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import apiClient  from '@/api/axios';


export default {
  data() {
    return {
      isEditing: false,
      isEditingCourier: false,
      isEditingNewPost: false,
      formData: {
        first_name: '',
        last_name: '',
        father_name: '',
        phone: '',
        country: '',
        city: '',
      },
      courierAddress: {
        street: '',
        house: '',
        apartment: '',
      },
      newPostAddress: {
        city: '',
        branch: '',
      },
      isChangingPassword: false,
      passwordData: {
      current_password: '',
      new_password: '',
      re_new_password: '',
    },
      courierErrors: {},
      newPostErrors: {},
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      passwordError: null,
      passwordSuccess: false,
      passwordSuccessInfo: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['getUser', 'getCourierAddress', 'getNewPostAddress']),
    ...mapActions('auth', ['changePasswordAction']), 
    isCourierValid() {
    return Object.keys(this.courierErrors).length === 0;
  },
    isNewPostValid() {
      return Object.keys(this.newPostErrors).length === 0;
    },

  },
  watch: {
    getUser(newUser) {
      if (newUser) this.formData = { ...newUser };
    },
    getCourierAddress(newAddress) {
      this.courierAddress = { ...newAddress };
    },
    getNewPostAddress(newAddress) {
      this.newPostAddress = { ...newAddress };
    },
  },
  methods: {
    ...mapActions('auth', [
      'fetchUser',
      'updateUser',
      'fetchCourierAddress',
      'update_courier',
      'fetchNewPostAddress',
      'update_np',
    ]),
    // eslint-disable-next-line 
    togglePasswordChange() {
      this.isChangingPassword = !this.isChangingPassword;
      if (!this.isChangingPassword) {
        this.resetPasswordData();
      }
    },
    resetPasswordData() {
    this.passwordData = {
      current_password: '',
      new_password: '',
      re_new_password: '',
    };
    this.passwordError = null;
    this.passwordSuccess = false;
  },
  async changePassword() {
      this.passwordError = null;
      this.passwordSuccess = false;
      try {
        // eslint-disable-next-line 
        const response = await apiClient.post('/auth/users/set_password/', this.passwordData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });
        this.passwordSuccessInfo = this.$t('passwordChange.successMessage');
        this.resetPasswordData();
        this.isChangingPassword = false;
      } catch (error) {
        if (error.response) {
          // Сервер відповів з кодом помилки
          if (error.response.data) {
            const errors = Object.entries(error.response.data)
              .map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join(', ') : value}`)
              .join('\n');
            this.passwordError = `Помилка: ${errors}`;
          } else {
            this.passwordError = 'Невідома помилка сервера.';
          }
        } else if (error.request) {
          // Запит був відправлений, але відповіді не отримано
          this.passwordError = 'Сервер не відповідає. Спробуйте пізніше.';
        } else {
          // Інша помилка
          this.passwordError = `Помилка: ${error.message}`;
        }
      }
    },

    toggleEditing() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) this.resetPersonalInfo();
    },
    toggleEditingCourier() {
      this.isEditingCourier = !this.isEditingCourier;
      if (!this.isEditingCourier) this.resetCourierAddress();
    },
    toggleShowPassword(field) {
      if (field === 'current') this.showCurrentPassword = !this.showCurrentPassword;
      else if (field === 'new') this.showNewPassword = !this.showNewPassword;
      else if (field === 'confirm') this.showConfirmPassword = !this.showConfirmPassword;
    },
    toggleEditingNewPost() {
      this.isEditingNewPost = !this.isEditingNewPost;
      if (!this.isEditingNewPost) this.resetNewPostAddress();
    },
    resetPersonalInfo() {
      this.formData = { ...this.getUser };
    },
    resetCourierAddress() {
      this.courierAddress = { ...this.getCourierAddress };
    },
    resetNewPostAddress() {
      this.newPostAddress = { ...this.getNewPostAddress };
    },
    async updatePersonalInfo() {
      try {
        await this.updateUser(this.formData);
        await this.fetchUser();
        this.isEditing = false;
      } catch (error) {
        console.error('Error updating personal info:', error);
      }
    },
    validateCourierField(field) {
      const value = this.courierAddress[field];
      // Перевірка на спецсимволи
      const specialCharRegex = /[^a-zA-Z0-9а-яА-Я\s]/;
      if (specialCharRegex.test(value)) {
        this.courierErrors[field] = this.$t('validation.special_characters');
        return;
      }
      // Успішна валідація
      delete this.courierErrors[field]; // Видалення помилки
    },

    validateNewPostField(field) {
      const value = this.newPostAddress[field];

      // Перевірка на спецсимволи
      const specialCharRegex = /[^a-zA-Z0-9а-яА-Я\s]/;
      if (specialCharRegex.test(value)) {
        this.newPostErrors[field] = 'Поле не може містити спецсимволів';
        return;
      }

      // Успішна валідація
      delete this.newPostErrors[field]; // Видалення помилки
    },

  async updateCourierAddress() {
    if (!this.isCourierValid) return; // Перевіряємо валідність перед оновленням
    try {
      await this.update_courier(this.courierAddress);
      await this.fetchCourierAddress();
      this.isEditingCourier = false;
    } catch (error) {
      console.error('Error updating courier address:', error);
    }
  },
  async updateNewPostAddress() {
    if (!this.isNewPostValid) return; // Перевіряємо валідність перед оновленням
    try {
      await this.update_np(this.newPostAddress);
      await this.fetchNewPostAddress();
      this.isEditingNewPost = false;
    } catch (error) {
      console.error('Error updating new post address:', error);
    }
  },
  },
  async mounted() {
    await Promise.all([
      this.fetchUser(),
      this.fetchCourierAddress(),
      this.fetchNewPostAddress(),
    ]);
  },
};
</script>


<style scoped>
.heading img {
  display: none;
}
.error {
  color: red;
}
.success {
  color: green;
}
.success-message {
  color: green;
}
.form .fields input {
  padding: 16px 16px;
  border: none;
  border-radius: 4px;
  transition: box-shadow 0.2s ease-in-out;
}

.form .fields input.active {
  box-shadow: inset 0 0 0 2px var(--clr-1); /* Бордер при isChangingPassword */
}

.form .fields input:disabled {
  box-shadow: none; /* Вимикає тінь, якщо поле неактивне */
}
.password-field {
  position: relative;
  display: flex;
  align-items: center;
}
.password-field .toggle-password {
  background: none;
  border: none;
  position: absolute;
  right: 50px;
  cursor: pointer;
}
.password-field img {
  width: 20px;
  height: 20px;
}
.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 4px;
}


</style>
