<template>
  <div class="product-reviews">
    <!-- Радіокнопки для перемикання між відгуками та запитаннями -->
    <input
      type="radio"
      name="reviews__type"
      id="reviews__type__comments"
      v-model="selectedTab"
      value="comments"
      checked
    />
    <input
      type="radio"
      name="reviews__type"
      id="reviews__type__questions"
      v-model="selectedTab"
      value="questions"
    />

    <div class="select__buttons">
      <label for="reviews__type__comments">{{ $t('reviews.comments') }}</label>
      <label for="reviews__type__questions">{{ $t('reviews.questions') }}</label>
    </div>
    <!-- Список відгуків -->
    <div v-if="selectedTab === 'comments'" class="select__block reviews__comments">
      <ul>
        <li v-for="comment in comments" :key="comment.id">
          <div class="review-header">
            <span class="user-name">{{ comment.user_name }}</span>
            <span class="review-date">{{ formatDate(comment.date) }}</span>
          </div>
          <div class="review-body">
            <p class="review-text">{{ comment.text }}</p>
            <p class="review-rate">
              <span v-for="(star, index) in comment.get_rate" :key="index">
                <i class="fas" :class="star ? 'fa-star' : 'fa-star-half-alt'"></i>
              </span>
            </p>
          </div>
          <div class="review-footer">
            <button @click="likeComment(comment.id)">👍 {{ comment.like_count }}</button>
            <button @click="dislikeComment(comment.id)">👎 {{ comment.dislike_count }}</button>
          </div>
          <ul class="replies-list">
          <li v-for="reply in comment.replies" :key="reply.id" class="reply-item">
            <div class="reply-header">
              <span class="user-name">{{ reply.user_name }}</span>
              <span class="reply-date">{{ formatDate(reply.date) }}</span>
            </div>
            <div class="reply-body">{{ reply.text }}</div>
          </li>
        </ul>
          <!-- Форма додавання відповіді -->
        <form v-if="isAuthenticated" @submit.prevent="submitReply(comment.id, replyInputs[comment.id])" class="reply-form">
          <textarea
            v-model="replyInputs[comment.id]"
            :placeholder="$t('reviews.add_reply_placeholder')"
            required
          ></textarea>
          <button type="submit" class="btn btn-secondary">{{ $t('reviews.submit_reply') }}</button>
        </form>

        </li>
      </ul>
    </div>

    <!-- Список запитань -->
    <div v-else class="reviews__questions">
      <ul>
        <li v-for="question in questions" :key="question.id">
          <div class="question-header">
            <span class="user-name">{{ question.user_name }}</span>
            <span class="question-date">{{ formatDate(question.date) }}</span>
          </div>
          <div class="question-body">{{ question.text }}</div>
         <!-- Список відповідей -->
        <ul class="replies-list">
          <li v-for="reply in question.replies" :key="reply.id" class="reply-item">
            <div class="reply-header">
              <span class="user-name">{{ reply.user_name }}</span>
              <span class="reply-date">{{ formatDate(reply.date) }}</span>
            </div>
            <div class="reply-body">{{ reply.text }}</div>
          </li>
        </ul>

          <!-- Форма додавання відповіді -->
          <form v-if="isAuthenticated" @submit.prevent="submitReply(question.id, replyInputs[question.id])" class="reply-form">
            <textarea
              v-model="replyInputs[question.id]"
              :placeholder="$t('reviews.add_reply_placeholder')"
              required
            ></textarea>
            <button type="submit" class="btn btn-secondary">{{ $t('reviews.submit_reply') }}</button>
          </form>

        </li>
      </ul>
    </div>

    <!-- Форма для додавання нового відгуку або питання -->
    <form v-if="isAuthenticated" @submit.prevent="submitReview" class="review-form">
      <textarea v-model="newReview.text" :placeholder="getPlaceholderText" required></textarea>
      <div v-if="selectedTab === 'comments'" class="review-rating">
        <label v-for="rate in [1, 2, 3, 4, 5]" :key="rate">
          <input type="radio" name="rating" v-model="newReview.rate" :value="rate" />
          <i class="fas fa-star"></i>
        </label>
      </div>
      <button type="submit" class="btn btn-primary">{{ $t('reviews.submit') }}</button>
    </form>

    <!-- Повідомлення для неавторизованих користувачів -->
    <div v-else class="not-authenticated-message">
      {{ $t('reviews.not_authenticated') }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProductReviews",
  props: {
    productId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      replyInputs: {}, 
      selectedTab: "comments",
      newReview: {
        type: "COMMENT",
        text: "",
        rate: 5,
      },
      newReply: {
        text: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      getComments: "comments/getComments",
      getUser: "auth/getUser",
      isAuthenticated: "auth/isAuthenticated",
    }),
    comments() {
      return this.getComments.filter(
        (comment) => comment.type === "COMMENT" && comment.product === this.productId
      );
    },
    questions() {
      return this.getComments.filter(
        (question) => question.type === "QUESTION" && question.product === this.productId
      );
    },
    getReplies() {
      return (parentId) => this.getComments
        .filter((reply) => reply.parent === parentId)
        .map((reply) => ({ ...reply }));
    },
    getPlaceholderText() {
      return this.selectedTab === "comments"
        ? this.$t("reviews.add_comment_placeholder")
        : this.$t("reviews.add_question_placeholder");
    },
  },
  methods: {
    ...mapActions("comments", ["fetchComments", "addComment", "addReply"]),
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    async submitReview() {
      if (!this.isAuthenticated) {
        alert(this.$t("reviews.not_authenticated"));
        return;
      }
      try {
        this.newReview.type = this.selectedTab === "comments" ? "COMMENT" : "QUESTION";
        this.newReview.product = this.productId;
        this.newReview.user = this.getUser.id;

        await this.addComment(this.newReview);
        this.newReview.text = "";
        this.newReview.rate = 5;

        await this.fetchComments(this.productId);
      } catch (error) {
        console.error("Error submitting review:", error);
      }
    },
    async submitReply(commentId, replyText) {
  if (!this.isAuthenticated) {
    alert(this.$t("reviews.not_authenticated"));
    return;
  }
  try {
    const replyData = {
      text: replyText,
      comment: commentId, // Завжди передаємо ID коментаря
    };

    await this.addReply(replyData); // Відправляємо дані у Vuex action
    this.replyInputs[commentId] = ""; // Очищаємо поле після успішного додавання
    await this.fetchComments(this.productId); // Оновлюємо список коментарів
  } catch (error) {
    console.error("Error submitting reply:", error);
  }
},
async likeComment(commentId) {
    try {
      if (!commentId) {
        console.error("Comment ID is undefined in likeComment");
        return;
      }
      console.log("Передаємо ID коментаря для лайку:", commentId);
      await this.$store.dispatch("comments/likeComment", commentId); // Передаємо commentId
      await this.fetchComments(this.productId); // Оновлюємо коментарі для поточного продукту
    } catch (error) {
      console.error("Помилка при лайкуванні коментаря:", error);
    }
  },

  async dislikeComment(commentId) {
    try {
      if (!commentId) {
        console.error("Comment ID is undefined in dislikeComment");
        return;
      }
      console.log("Передаємо ID коментаря для дизлайку:", commentId);
      await this.$store.dispatch("comments/dislikeComment", commentId); // Передаємо commentId
      await this.fetchComments(this.productId); // Оновлюємо коментарі для поточного продукту
    } catch (error) {
      console.error("Помилка при дизлайкуванні коментаря:", error);
    }
  },},
  async mounted() {
  try {
    if (!this.productId) {
      console.error("Product ID is undefined");
      return;
    }

    // Отримуємо коментарі для продукту
    await this.fetchComments(this.productId);
    

    // Отримуємо інформацію про користувача, якщо він авторизований
    if (this.isAuthenticated) {
      await this.$store.dispatch("auth/fetchUser");
    }
  } catch (error) {
    console.error("Error during mounted:", error);
  }
}

};
</script>

<style scoped>
/* Додаткові стилі для відображення відповідей */
.replies-list {
  list-style: none;
  margin: 10px 0 0 20px;
  padding: 0;
}

.reply-item {
  margin-bottom: 10px;
  padding: 10px;
  border-left: 2px solid #ddd;
}

.reply-header {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #555;
}

.reply-body {
  margin-top: 5px;
  font-size: 14px;
}

.reply-form {
  margin-top: 10px;
}

.reply-form textarea {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.reply-form button {
  margin-top: 5px;
}
  /* Стилізація */
  .select__buttons {
    display: flex;
    justify-content: flex-start;
    margin-top: 32px;
  }
  .select__buttons label {
    padding: 16px 2px;
    border-bottom: 2px solid whitesmoke;
    margin-right: 16px;
    cursor: pointer;
    width: 100%;
  }
  .select__buttons label:last-child {
    margin-right: 0px;
  }
  input[name="reviews__type"] {
    display: none;
  }
  input#reviews__type__comments:checked ~ .select__buttons label[for="reviews__type__comments"],
  input#reviews__type__questions:checked ~ .select__buttons label[for="reviews__type__questions"] {
    border-color: var(--clr-1);
    color: var(--clr-1-spr-drk);
    font-weight: 600;
  }
.reviews-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.review-item {
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin-bottom: 10px;
  background: #f9f9f9;
}

.review-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
  color: #555;
}

.review-body {
  margin-bottom: 10px;
}

.review-footer {
  display: flex;
  gap: 10px;
}

.review-form {
  margin-top: 20px;
}

.review-form textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.review-rating label {
  cursor: pointer;
  margin-right: 5px;
}

.review-rating label i {
  font-size: 20px;
  color: #ffd700;
}

.review-rating label input {
  display: none;
}



.not-authenticated-message {
  text-align: center;
  font-size: 16px;
  color: #777;
}
.btn.btn-primary {
  background-color: var(--clr-2);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
}
.review-form button:hover{
    background-color: var(--clr-3);
}

/* NEW */
/* Загальні стилі */
.reviews-section {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-top: 20px;
}

.review-item {
  padding: 15px;
  margin-bottom: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.question-item {
  padding: 15px;
  margin-bottom: 15px;
  background-color: #e9f5ff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.review-header,
.question-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.review-body,
.question-body {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.6;
}

.review-footer {
  display: flex;
  gap: 10px;
}

.replies-list {
  list-style: none;
  padding-left: 20px;
  margin-top: 10px;
}

.reply-item {
  margin-bottom: 10px;
  padding: 10px;
  border-left: 2px solid #ddd;
  background-color: #f3f3f3;
  border-radius: 6px;
}

.reply-header {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
}

.reply-body {
  font-size: 14px;
  margin-top: 5px;
}

.reply-form textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.reply-form button {
  margin-top: 5px;
  background-color: var(--clr-2);
  color: #fff;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.reply-form button:hover {
  background-color: var(--clr-3);
}

  </style>
  