import apiClient from './axios';

// Кешування для банерів
export const fetchBanners = async () => {
  const cacheKey = 'banners';
  const cacheExpiration = 3600 * 1000; // 1 година (в мілісекундах)
  
  const cachedData = localStorage.getItem(cacheKey);
  if (cachedData) {
    const { data, timestamp } = JSON.parse(cachedData);
    const isCacheValid = Date.now() - timestamp < cacheExpiration;
    if (isCacheValid) {
      console.log('Serving banners from cache');
      return { data };
    }
  }

  console.log('Fetching banners from API');
  const response = await apiClient.get('/banners/banners/');
  localStorage.setItem(cacheKey, JSON.stringify({ data: response.data, timestamp: Date.now() }));
  return response;
};

// Кешування для банерів-опцій
export const fetchBannerOpt = async () => {
  const cacheKey = 'bannersOpt';
  const cacheExpiration = 3600 * 1000; // 1 година (в мілісекундах)
  
  const cachedData = localStorage.getItem(cacheKey);
  if (cachedData) {
    const { data, timestamp } = JSON.parse(cachedData);
    const isCacheValid = Date.now() - timestamp < cacheExpiration;
    if (isCacheValid) {
      console.log('Serving banner options from cache');
      return { data };
    }
  }

  console.log('Fetching banner options from API');
  const response = await apiClient.get('/banners/banners-opt/');
  localStorage.setItem(cacheKey, JSON.stringify({ data: response.data, timestamp: Date.now() }));
  return response;
};
