<template>
    <div class="content">
      <p class="h1 mt48 center">{{ $t('registration_confirmation.thank_you') }}</p>
      <p class="h2 mt16 mb16 center">
        {{ $t('registration_confirmation.activation_instructions') }}
      </p>
      <img
        style="width: 100%; max-width: 640px; display: block; margin: 32px auto; border-radius: 4px;"
        src="@/assets/img/sahmpoo.gif"
        alt="sahmpoo"
      />
      <div class="back_btn">
        <a class="h2 color-2 block center mt16 mb16" href="/">
          {{ $t('registration_confirmation.go_to_home') }}</a>
      </div>

    </div>
  </template>
  
<script>
export default {
    name: 'RegistrationConfirmation',
};
</script>

<style scoped>
.back_btn {
  display: flex;
  justify-content: center;
}
.back_btn a {
  display: inline-block;
  padding: 8px 24px;
  border: 2px solid var(--clr-1);
  background-color: transparent;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  background-color: whitesmoke;
  text-wrap: nowrap;
}
.back_btn a:hover {
  background: var(--clr-1);
}
</style>
