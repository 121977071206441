import apiClient from './axios';

const CACHE_KEY = 'advantages_cache';
const CACHE_EXPIRY = 3600; // Час життя кешу в секундах (1 година)

export const fetchAdvantages = async () => {
  const now = Math.floor(Date.now() / 1000);

  // Перевірка кешу
  const cached = localStorage.getItem(CACHE_KEY);
  if (cached) {
    const cachedData = JSON.parse(cached);

    // Перевіряємо, чи кеш ще актуальний
    if (now - cachedData.timestamp < CACHE_EXPIRY) {
      console.log('Використовуються кешовані дані');
      return cachedData;
    }
  }

  // Якщо дані в кеші не актуальні або їх немає, робимо запит
  try {
    const response = await apiClient.get('/core/advantages/');
    const data = response.data;

    // Оновлюємо кеш
    localStorage.setItem(
      CACHE_KEY,
      JSON.stringify({ timestamp: now, data })
    );

    console.log('Дані завантажено з API та кешовано');
    return data;
  } catch (error) {
    console.error('Помилка під час отримання даних:', error);

    // У разі помилки повертаємо кешовані дані, якщо вони є
    if (cached) {
      const cachedData = JSON.parse(cached);
      console.warn('Повертаються старі кешовані дані');
      return cachedData.data;
    }

    throw error; // Якщо немає кешу, кидаємо помилку
  }
};
