<template>
  <main>
    <div class="content">
      <div class="breadcrumbs">
        <a href="/">
          <span>{{ $t ('breadcrambs.home')}}</span>
        </a>
        <span class="slash">/</span>
        <span>{{ $t ('breadcrambs.user_page')}}</span>
      </div>
    </div>
    <div class="content">
      <div class="profile">
        <div>
          <ul class="navigation">
            <li @click="setActiveSection('personalInfo')" :class="{ active: activeSection === 'personalInfo' }">
              <img src="@/assets/img/icons/svg/Avatar.svg" alt="*">
              <p>{{ $t('navigation.personal_info') }}</p>
            </li>
            <li @click="setActiveSection('orders')" :class="{ active: activeSection === 'orders' }">
              <img src="@/assets/img/icons/svg/Basket.svg" alt="*">
              <p>{{ $t('navigation.orders') }}</p>
            </li>
            <li @click="setActiveSection('wishlist')" :class="{ active: activeSection === 'wishlist' }">
              <img src="@/assets/img/icons/svg/Heart.svg" alt="*">
              <p>{{ $t('navigation.wishlist') }}</p>
            </li>
            <li v-if="isWhoosaler" @click="setActiveSection('companyInfo')" :class="{ active: activeSection === 'companyInfo' }">
              <img src="@/assets/img/icons/svg/Page.svg" alt="*">
              <p>{{ $t('navigation.company_info') }}</p>
            </li>
            <li @click="setActiveSection('reviews')" :class="{ active: activeSection === 'reviews' }">
              <img src="@/assets/img/icons/svg/Comment.svg" alt="*">
              <p>{{ $t('navigation.reviews') }}</p>
            </li>
            <li @click="logoutUser">
              <img src="@/assets/img/icons/svg/Forward_2.svg" alt="*">
              <p>{{ $t('navigation.logout') }}</p>
            </li>
          </ul>
        </div>
        <div class="data profile_data">
          <!-- Динамічне відображення компонентів -->
          <component :is="currentComponent" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PersonalInfo from '@/components/profile/PersonalInfo.vue';
import Orders from '@/components/profile/Orders.vue';
import Wishlist from '@/components/profile/Wishlist.vue';
import CompanyInfo from '@/components/profile/CompanyInfo.vue';
import Reviews from '@/components/profile/ReviewsComponent.vue';

export default {
  name: 'UserProfile',
  components: {
    PersonalInfo,
    Orders,
    Wishlist,
    CompanyInfo,
    Reviews
  },
  data() {
    return {
      activeSection: 'personalInfo' // Початковий розділ
    };
  },
  computed: {
    ...mapGetters('auth', ['isWhoosaler']),
    currentComponent() {

      switch (this.activeSection) {
        case 'orders':
          return Orders;
        case 'wishlist':
          return Wishlist;
        case 'companyInfo':
          return this.isWhoosaler ? CompanyInfo : null;
        case 'reviews':
          return Reviews;
        default:
          return PersonalInfo;
      }
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    setActiveSection(section) {
      this.activeSection = section;
    },
    async logoutUser() {
      try {
        await this.logout();
        this.$router.push('/');
      } catch (error) {
        console.error("Помилка логауту", error);
      }
    }
  }
};
</script>

<style scoped>
@import '@/assets/css/user/profile/profile.css';
@import '@/assets/css/user/profile/from.css';
@import '@/assets/css/user/profile/profile__navigation.css';
@import '@/assets/css/user/profile/profile__orders.css';

.navigation .active {
  font-weight: bold;
}
.navigation li {
  cursor: pointer;
}
</style>
