import apiClient from "@/api/axios";

const state = {
  userReviews: [], // Список відгуків користувача
};

const mutations = {
  SET_USER_REVIEWS(state, reviews) {
    state.userReviews = reviews;
  },
};

const actions = {
  async fetchUserReviews({ commit }) {
    try {
      const response = await apiClient.get("/shop/user-reviews/");
      commit("SET_USER_REVIEWS", response.data); // Зберігаємо отримані відгуки
    } catch (error) {
      console.error("Error fetching user reviews:", error);
    }
  },
};

const getters = {
  userReviews: (state) => state.userReviews,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
