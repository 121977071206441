<template>  
  <div class="home-advantages">
    <div class="content img">
      <p class="title1 center">{{ $t('homeAdvantages.title') }}</p>
      <div class="grid-container">
        <div
          class="grid-item"
          v-for="advantage in advantages"
          :key="advantage.id"
        >
          <div class="img-wrapper">
            <img
              :src="advantage.image"
              alt="advantage_img"
              width="337"
              height="443"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchAdvantages } from '@/api/advantagesData';


export default {
  data() {
    return {
      advantages: [],
    };
  },
  mounted() {
    this.load_advantages();
  },
  methods: {
    async load_advantages() {
      try {
        const response = await fetchAdvantages();
        this.advantages = response.data;
      } catch (error) {
        console.error('Помилка завантаження переваг:', error);
      }
    },
  },
};
</script>

<style scoped>
.home-advantages .content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.grid-item {
  text-align: center;
  overflow: hidden;
  border: 2px solid #f5f5f5;
  transition: all 0.2s ease-in-out;
}

.grid-item:hover {
  transform: translateY(-8px); 
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1); 
  border-color: var(--clr-1); 
}

.img-wrapper {
  width: 100%;
  height: 440px;
  padding-bottom: 66.67%;
  position: relative;
  overflow: hidden;
}

.img-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.advantage-title {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr); 
  }
  .img-wrapper {
  width: 100%;
  height: 400px;
}
}

@media (max-width: 640px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
  .img-wrapper {
    height: 380px;
  }
}
</style>
