<template>

    <div class="content">
      <div class="partner_data">
        <h1 class="center">{{ $t ('partner_form.title')}}</h1>
        <form @submit.prevent="submitForm" class="form">
          <!-- Поле прізвище -->
          <div class="form-row">
            <div class="form-group">
              <input v-model="formData.last_name" type="text" class="form-control" :placeholder="$t ('partner_form.last_name')" required />
              <span v-if="errors.last_name" class="error-message">{{ errors.last_name }}</span>
            </div>
            <div class="form-group">
              <input v-model="formData.first_name" type="text" class="form-control" :placeholder="$t ('partner_form.first_name')" required />
              <span v-if="errors.first_name" class="error-message">{{ errors.first_name }}</span>
            </div>
            
          </div>
  
          <!-- Поле по-батькові та email -->
          <div class="form-row">
            <div class="form-group">
              <input v-model="formData.patronymic" type="text" class="form-control" :placeholder="$t ('partner_form.patronymic')" required />
              <span v-if="errors.patronymic" class="error-message">{{ errors.patronymic }}</span>
            </div>
            <div class="form-group">
              <input v-model="formData.email" type="email" class="form-control" :placeholder="$t ('partner_form.email')" required />
              <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
            </div>
          </div>
  
          <!-- Поле телефон та дата народження -->
          <div class="form-row">
            <div class="form-group">
              <input v-model="formData.phone_number" type="text" class="form-control" placeholder="+__(___)___-__-__*" required />
              <span v-if="errors.phone_number" class="error-message">{{ errors.phone_number }}</span>
            </div>
            <div class="form-group">
              <input v-model="formData.date_of_birth" type="date" :placeholder="$t ('partner_form.birthday')" class="form-control" required />
              <span v-if="errors.date_of_birth" class="error-message">{{ errors.date_of_birth }}</span>
            </div>
          </div>
  
          <!-- Вибір посади та освіти -->
          <div class="form-row">
            <div class="form-group">
              <label for="position">{{ $t ('partner_form.position')}}</label>
              <select v-model="formData.position" class="form-control" required>
                <option value="" disabled selected>{{ $t ('partner_form.select_from_list')}}</option>
       
                <option v-for="position in positions" :key="position.id" :value="position.id">{{ position.name }}</option>
              </select>
              <span v-if="errors.position" class="error-message">{{ errors.position }}</span>
            </div>
            <div class="form-group">
              <label for="education">{{ $t ('partner_form.education')}}</label>
              <select v-model="formData.education" class="form-control" required>
                <option value="" disabled selected>{{ $t ('partner_form.select_from_list')}}</option>
                <option v-for="education in educations" :key="education.id" :value="education.id">{{ education.name }}</option>
              </select>
              <span v-if="errors.education" class="error-message">{{ errors.education }}</span>
            </div>
          </div>
  
          <!-- Поля спеціалізації та місця роботи -->
          <div class="form-row">
            <div class="form-group">
              <label for="specialization">{{ $t ('partner_form.main_specialization')}}</label>
              <select v-model="formData.specialization" class="form-control" required>
                <option value="" disabled selected>{{ $t ('partner_form.select_from_list')}}</option>
                <option v-for="specialization in specializations" :key="specialization.id" :value="specialization.id">{{ specialization.name }}</option>
              </select>
              <span v-if="errors.specialization" class="error-message">{{ errors.specialization }}</span>
            </div>
            <div class="form-group">
              <label for="workplace">{{ $t ('partner_form.working_place')}}</label>
              <select v-model="formData.workplace" class="form-control" required>
                <option value="" disabled selected>{{ $t ('partner_form.select_from_list')}}</option>
                <option v-for="workplace in workplaces" :key="workplace.id" :value="workplace.id">{{ workplace.name }}</option>
              </select>
              <span v-if="errors.workplace" class="error-message">{{ errors.workplace }}</span>
            </div>
          </div>
  
          <!-- Поле назва клініки та адреса -->
          <div class="form-group">
            <input v-model="formData.clinic_name" type="text" class="form-control" :placeholder="$t ('partner_form.clinic_name')" required />
            <span v-if="errors.clinic_name" class="error-message">{{ errors.clinic_name }}</span>
          </div>
          <div class="form-group">
            <input v-model="formData.address" type="text" class="form-control" :placeholder="$t ('partner_form.address')" required />
            <span v-if="errors.address" class="error-message">{{ errors.address }}</span>
          </div>
  
          <!-- Поле соцмережі та сайт клініки -->
          <div class="form-row">
        <div class="form-group">
            <input
            v-model="formData.professional_social_media"
            @blur="formatUrl('professional_social_media')"
            type="text"
            class="form-control"
            :placeholder="$t ('partner_form.professional_social_media')"
            />
            <span v-if="errors.professional_social_media" class="error-message">{{ errors.professional_social_media }}</span>
        </div>
        <div class="form-group">
            <input
            v-model="formData.clinic_website"
            @blur="formatUrl('clinic_website')"
            type="text"
            class="form-control"
            :placeholder="$t ('partner_form.clinic_website')"
            />
            <span v-if="errors.clinic_website" class="error-message">{{ errors.clinic_website }}</span>
        </div>
        </div>

          <!-- Поле як дізнались -->
          <div class="form-group">
            <label for="how_they_heard_about_us">{{ $t ('partner_form.how_they_heard_about_us')}}</label>
            <select v-model="formData.how_they_heard_about_us" class="form-control">
              <option value="" disabled selected>{{ $t ('partner_form.select_from_list')}}</option>
              <option v-for="item in how_they" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
            <span v-if="errors.how_they_heard_about_us" class="error-message">{{ errors.how_they_heard_about_us }}</span>
          </div>
  
          <div class="form-group">
            <!-- Лейбл над полем -->
            <label :for="'profile_certificate_photo'">
              {{ $t('partner_form.certificate_photo') }}
            </label>

            <!-- Кастомний дизайн для інпуту -->
            <div class="custom-file-input-wrapper">
              <input
                type="file"
                id="profile_certificate_photo"
                class="file-input"
                accept="image/*"
                @change="handleFileChange"
              />
              <span v-if="errors.profile_certificate_photo" class="error-message">{{ errors.profile_certificate_photo }}</span>
              <!-- Текст для кастомного блоку -->
              <label for="profile_certificate_photo" class="custom-file-label">
                {{ selectedFileName || $t('partner_form.selectFile') }}
              </label>
            </div>
          </div>
          <!-- Кнопка відправки -->
          <button type="submit" enctype="multipart/form-data" class="btn btn-primary">{{ $t ('partner_form.submit')}}</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import PartnerService from "@/api/PartnerService";
  
  export default {
    data() {
      return {
        formData: {
          last_name: "",
          first_name: "",
          patronymic: "",
          email: "",
          phone_number: "",
          date_of_birth: "",
          position: "",
          education: "",
          specialization: "",
          workplace: "",
          clinic_name: "",
          address: "",
          professional_social_media: "",
          clinic_website: "",
          brands_worked_with: "",
          how_they_heard_about_us: "",
          profile_certificate_photo: null,
          
        },
        errors: {}, 
        validationRules: {
          last_name: { required: true, minLength: 2 },
          first_name: { required: true, minLength: 2 },
          patronymic: { required: true, minLength: 2 },
          email: { required: true, email: true },
          phone_number: { required: true, phone: true },
          date_of_birth: { required: true, date: true },
          position: { required: true },
          education: { required: true },
          specialization: { required: true },
          workplace: { required: true },
          clinic_name: { required: true, minLength: 2 },
          address: { required: true, minLength: 5 },
          professional_social_media: { url: true },
          clinic_website: { url: true },
          how_they_heard_about_us: { required: true },
          profile_certificate_photo: { required: true, file: true },
        },

        selectedFileName: null,
        positions: [],
        educations: [],
        specializations: [],
        workplaces: [],
        how_they: [],
      };
    },
    mounted() {
  PartnerService.getFormOptions()
    .then((response) => {
      this.positions = response.data.positions;
      this.educations = response.data.educations;
      this.specializations = response.data.specializations;
      this.workplaces = response.data.workplaces;
      this.how_they = response.data.how_they;
      console.log(
        "Positions:", this.positions,
        "Educations:", this.educations,
        "Specializations:", this.specializations,
        "Workplaces:", this.workplaces,
        "How they heard:", this.how_they
      );
    })
    .catch((error) => {
      console.error("Error fetching form options:", error);
    });
},

    methods: {
      validateField(field, value) {
  const rules = this.validationRules[field];
  this.errors[field] = null; // Очистити попередні помилки

  if (rules?.required && (!value || (typeof value === "string" && value.trim() === ""))) {
    this.errors[field] = this.$t("validation.required");
    return false;
  }

  // Заборона введення лише спеціальних символів
  if (typeof value === "string" && /^[^a-zA-Z0-9а-яА-Я]+$/.test(value)) {
    this.errors[field] = this.$t("validation.only_special_chars");
    return false;
  }

  if (rules?.minLength && typeof value === "string" && value.trim().length < rules.minLength) {
    this.errors[field] = this.$t("validation.min_length", { length: rules.minLength });
    return false;
  }

  if (rules?.email && typeof value === "string" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
    this.errors[field] = this.$t("validation.invalid_email");
    return false;
  }

  if (rules?.phone && typeof value === "string" && !/^\+\d{12}$/.test(value)) {
    this.errors[field] = this.$t("validation.invalid_phone");
    return false;
  }


  if (rules?.url && typeof value === "string" && value && !/^https?:\/\/[^\s]+$/.test(value)) {
    this.errors[field] = this.$t("validation.invalid_url");
    return false;
  }

  if (rules?.date) {
    const today = new Date();
    const inputDate = new Date(value);
    if (inputDate >= today) {
      this.errors[field] = this.$t("validation.invalid_date");
      return false;
    }
  }

  if (rules?.file && !(value instanceof File)) {
    this.errors[field] = this.$t("validation.invalid_file");
    return false;
  }

  return true;
},

  validateForm() {
    const fields = Object.keys(this.formData);
    let isValid = true;

    fields.forEach((field) => {
      if (!this.validateField(field, this.formData[field])) {
        isValid = false;
      }
    });

    return isValid;
  },

        formatUrl(field) {
    const url = this.formData[field];
    if (url && !url.startsWith("http://") && !url.startsWith("https://")) {
      this.formData[field] = `https://${url}`;
    }
  },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.selectedFileName = file ? file.name : null; 
    },
    handleFileUpload(event) {
        if (event.target.files.length > 0) {
        this.formData.profile_certificate_photo = event.target.files[0];
        }
    },
      submitForm() {
        if (!this.validateForm()) {
          alert(this.$t("validation.fix_errors"));
          return;
        }
        let formData = new FormData();
        for (let key in this.formData) {
          formData.append(key, this.formData[key]);
        }
  
        PartnerService.submitPartnerForm(formData)
          .then((response) => {
            alert(response.data.success);
            this.resetForm();
          })
          .catch((error) => {
            console.error("Error submitting form:", error);
            alert("Помилка при відправці форми.");
          });
      },
      resetForm() {
        this.formData = {
          last_name: "",
          first_name: "",
          patronymic: "",
          email: "",
          phone_number: "",
          date_of_birth: "",
          position: "",
          education: "",
          specialization: "",
          workplace: "",
          clinic_name: "",
          address: "",
          professional_social_media: "",
          clinic_website: "",
          brands_worked_with: "",
          how_they_heard_about_us: "",
          profile_certificate_photo: null,
        };
      },
    },
  };
  </script>
  
  <style scoped>
  .content {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .partner_data {
    width: 100%;
    max-width: 800px;
  }
  .partner_data h1 {
    margin-bottom: 35px;
  }
  .form {
    display: grid;
    gap: 15px;
    width: calc(100% - 24px);
  }
  
  .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  .form-control {
    padding: 10px 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  .form-control{
    color: #000;
  }
  .form-control option{
    color: #000;
  }
  .btn {
    padding: 10px 16px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  .file-input {
  display: block;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
  position: relative;
  z-index: 1;
}


  @media (max-width: 600px) {
    .form-row {
      grid-template-columns: 1fr;
    }

  }

  .custom-file-input-wrapper {
  position: relative;
  display: block;
  width: 100%;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* Ховаємо стандартний інпут */
  cursor: pointer;
  z-index: 2;
}

.custom-file-label {
  display: block;
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  text-align: center;
  position: relative;
  z-index: 1;
}

.custom-file-label:hover,
.file-input {
  background-color: var(--clr-1);
} 
.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 4px;
}

</style>
  
