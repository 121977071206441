<template>
  <div class="home">
    <!-- Десктопна версія -->
    <HomeSlider v-if="!isMobile && isSliderVisible" />

    <!-- Categories -->
    <div class="home__categories" v-if="!isMobile">
      <div class="content">
        <p class="title1">{{ $t('sidebar.categories')}}</p>
        <ul>
          <li v-for="category in categories" :key="category.id">
            <a :href="category.url">
              <img :src="category.image" :alt="category.name" loading="lazy" />
              <p class="name">{{ category.human ? category.human : category.name }}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Products (Новинки) -->
    <ProductsSlider
      v-if="isProductsVisible"
      :heading="$t('filter.sort.newest')"
      :products="products"
      @OpenWishList="openWish"
      @OpenCartList="openCart"
    />

    <!-- About Section -->
    <HomeAbout :slogan="slogan" :logo="logo" />

    <!-- Мобільна версія -->
    <HomeSlider v-if="isMobile && isSliderVisible" />

    <!-- Categories -->
    <div class="home__categories" v-if="isMobile">
      <div class="content">
        <p class="title1">{{ $t ('sidebar.categories')}}</p>
        <ul>
          <li v-for="category in categories" :key="category.id">
            <a :href="category.url">
              <img :src="category.image" :alt="category.name" loading="lazy" />
              <p class="name">{{ category.human ? category.human : category.name }}</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchLatestProducts } from '@/api/productService';
import { fetchFirstLevelCategories } from '@/api/categoryService';
import { defineAsyncComponent } from 'vue';
import HomeAbout from '@/components/HomeAbout.vue';

export default {
  name: 'HomePage',
  components: {
    HomeSlider: defineAsyncComponent(() => import('@/components/HomeSlider.vue')),
    ProductsSlider: defineAsyncComponent(() => import('@/components/ProductsSlider.vue')),
    HomeAbout,
  },
  data() {
    return {
      isMobile: false,
      isSliderVisible: false,
      isProductsVisible: false,
      products: [],
      categories: [],
      logo: require('@/assets/img/logo.png'),
    };
  },
  async mounted() {
    this.checkIfMobile();

    // Відображення слайдера та продуктів із затримкою для покращення сприйняття
    setTimeout(() => {
      this.isSliderVisible = true;
    }, 100);

    setTimeout(() => {
      this.isProductsVisible = true;
    }, 300);

    await Promise.all([this.loadCategories(), this.loadLatestProducts()]);

    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
    openWish() {
      this.$emit('OpenWishList');
    },
    openCart() {
      this.$emit('OpenCartList');
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    async loadCategories() {
      try {
        const cachedCategories = localStorage.getItem('categories');
        if (cachedCategories) {
          this.categories = JSON.parse(cachedCategories);
        } else {
          const data = await fetchFirstLevelCategories();
          this.categories = data;
          localStorage.setItem('categories', JSON.stringify(data));
        }
      } catch (error) {
        console.error("Не вдалося завантажити категорії:", error);
      }
    },
    async loadLatestProducts() {
      try {
        const cachedProducts = localStorage.getItem('latestProducts');
        if (cachedProducts) {
          this.products = JSON.parse(cachedProducts);
        } else {
          const response = await fetchLatestProducts();
          this.products = response.data.results.map(product => ({
            id: product.id,
            name: product.name,
            unit: product.unit,
            brand: product.brand.name,
            human: product.human,
            url: `/product/${product.slug}`,
            variants: product.variant.map(variant => ({
              id: variant.id,
              code: variant.code,
              value: variant.value,
              price: variant.price,
              discount_price: variant.discount_price,
              images: variant.images,
              url: variant.url,
              whoosale_price: variant.whoosale_price,
              discount_whoosale_price: variant.discount_whoosale_price,
              stock: variant.stock,
              stock_opt: variant.stock_opt,
            })),
          }));
          localStorage.setItem('latestProducts', JSON.stringify(this.products));
        }
      } catch (error) {
        console.error("Помилка завантаження новинок:", error);
      }
    },
  },
};
</script>

<style scoped>
@import '@/assets/css/home/home.css';
.home__categories {
  min-height: 200px;
}
.home__categories ul li img {
  width: 100%;
}
</style>
