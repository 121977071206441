<template>
  <div class="reviews">
    <h2>{{ $t('reviews.title') }}</h2>
    <div v-if="loading" class="loading">{{ $t('reviews.loading') }}</div>
    <div v-else>
      <div v-if="!reviews.length" class="no-reviews">
        <p>{{ $t('reviews.no_reviews') }}</p>
      </div>
      <ul v-else class="review-list">
        <li v-for="review in reviews" :key="review.id" class="review-item">
          <h3>
            <a :href="`/product/${review.product_slug}`" class="product-link">
              {{ review.product_name }}
            </a>
          </h3>
          <p class="review-text">
            <strong>{{ $t('reviews.comment') }}:</strong> {{ review.text }}
          </p>
          <p class="review-date">
            <strong>{{ $t('reviews.date') }}:</strong> {{ formatDate(review.date) }}
          </p>
          <ul class="reply-list">
            <li v-for="reply in review.replies" :key="reply.id" class="reply-item">
              <p class="reply-text">
                <strong>{{ $t('reviews.reply') }}:</strong> {{ reply.text }}
              </p>
              <p class="reply-date">
                <strong>{{ $t('reviews.reply_date') }}:</strong> {{ formatDate(reply.date) }}
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserReviews",
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("reviews", ["userReviews"]),
    reviews() {
      return this.userReviews;
    },
  },
  methods: {
    ...mapActions("reviews", ["fetchUserReviews"]),
    async loadReviews() {
      this.loading = true;
      try {
        await this.fetchUserReviews();
      } catch (error) {
        console.error("Error loading reviews:", error);
      } finally {
        this.loading = false;
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
  },
  async mounted() {
    await this.loadReviews();
  },
};
</script>
<style scoped>
.reviews {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.loading {
  text-align: center;
  font-size: 16px;
  color: #666;
}

.no-reviews {
  text-align: center;
  color: #999;
  font-style: italic;
}

.review-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.review-item {
  padding: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: transform 0.2s;
}

.review-item:hover {
  transform: translateY(-5px);
}

.product-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
}

.product-link:hover {
  text-decoration: underline;
}

.review-text,
.review-date {
  margin: 10px 0;
  font-size: 14px;
  color: #333;
}

.reply-list {
  list-style: none;
  padding: 0;
  margin-top: 10px;
  border-top: 1px solid #eee;
  padding-top: 10px;
}

.reply-item {
  margin-bottom: 10px;
}

.reply-text,
.reply-date {
  font-size: 13px;
  color: #555;
}
</style>
